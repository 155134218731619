
@import '../../../node_modules/@angular/material/theming';
@import './colors.scss';

// Copy the palettes from your selected theme (usually theme.scss).
$app-primary: mat-palette($app-palette-primary, 700);
$app-primary-light: mat-palette($app-palette-primary, 200);
$app-primary-dark: mat-palette($app-palette-primary, 900);
$app-accent: mat-palette($mat-blue, 400);
$app-accent-light: mat-palette($mat-blue, 50);
$app-accent-dark: mat-palette($mat-blue, 400);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-deep-orange, A700);

// Create your Sass color vars (will be available in all the project)
$primary: mat-color($app-primary);
$primary-light: mat-color($app-primary-light);
$primary-dark: mat-color($app-primary-dark);
$accent: mat-color($app-accent);
$accent-light: mat-color($app-accent-light);
$accent-dark: mat-color($app-accent-dark);
$warn: mat-color($app-warn);

// Borders
$dark-border: solid 1px #555;
$medium-border: solid 1px #aaa;
$light-border: solid 1px #eee;
$border-radius: 7px;

// Text
$dark-text: #555;
$small-text: 12px;
$small-medium-text: 14px;
$medium-text: 16px;
$large-text: 24px;

// Status Colors
$app-status-complete: green;
$app-status-pending: goldenrod;
$app-status-fail: mat-color($app-warn);
$app-status-light-green: #f1f8e9;

$black-color: #333;
$white-color: #FFF;
$white-color1: #F5F5F5;
$grey: #888888;
$sky-light-blue: #EEE;
$sky-light-blue1: #e9ecef;
$sky-light-blue2: #CFD8DC;
$mid-blue: #607D8B;
$sky-blue: #039be5;

$new-card-background: #E6E8EF;
$new-card-border: #ddd;