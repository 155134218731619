.phone-only,
.desktop-only {
  display: none;
}

@media only screen and (max-width: 960px) {
  .desktop-only {
    display: none;
  }

  .phone-only {
    display: block !important;
  }
}

@media only screen and (min-width: 960px) {
  .desktop-only {
    display: block !important;
  }

  .phone-only {
    display: none;
  }
}